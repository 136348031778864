.main-video-container video {
  height: 100%;
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: contain;
}

.Main-Video-Mob video {
  height: 100%;
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-box-shadow: 4px 11px 18px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 4px 11px 18px -4px rgba(0, 0, 0, 0.75);
  box-shadow: 4px 11px 18px -4px rgba(0, 0, 0, 0.75);
}

.thumb-video-container video {
  height: 100%;
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
}

.rotate-180 {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
}

.Main-Video-Background video {
  height: 100%;
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
}

.grid-container video {
  height: 100%;
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
}

.video-thumb {
  height: 300px;
  width: 300px;
  min-width: 300px;
  min-height: 300px;
}

.remote-audio {
  min-width: 0px;
  min-height: 0px;
  display: none;
}

.stream-component {
  position: absolute;
  padding: 10px;
  color: #fff;
  font-weight: bold;
  border-bottom-right-radius: 4px;
  opacity: 0.5;
  z-index: 5;
}

.user-icons {
  right: 5px;
  background: none;
  opacity: 0.7;
}

.mob-font {
  font-size: small;
}

p {
  margin: 0;
}

.pin-wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  background: #282c34;
  z-index: 2;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.audioindicator {
  display: inline-block;
  display: flex;
  justify-content: space-around;
  width: 24px;
  border: none;
}

.audiodot-top,
.audiodot-bottom,
.audiodot-middle {
  opacity: 1;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  background: rgba(9, 36, 77, 0.9);
  margin: 1px 0 1px 0;
  transition: opacity 0.25s ease-in-out;
  -moz-transition: opacity 0.25s ease-in-out;
}

span.audiodot-top,
span.audiodot-bottom,
span.audiodot-middle {
  content: "";
  opacity: 1;
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  -webkit-filter: blur(0.5px);
  filter: blur(0.5px);
  background: #44a5ff;
}
.hide-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.hide-scroll::-webkit-scrollbar {
  display: none;
}

.tile-container-mob {
  width: 15vh;
  position: relative;
  margin-left: 10px;
}
.tile-container {
  width: 90%;
  position: relative;
  aspect-ratio: 1;
  min-height: 10.5vw;
  max-height: 10.5vw;
  background-color: #000000;
  margin-bottom: 10px;
  border-radius: 0.7rem;
}

/* Hide scrollbar for IE, Edge and Firefox */
