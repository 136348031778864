.App {
  text-align: center;
  height: 100%;
  position: relative;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  justify-content: center;
  height: 100%;
}

/* .tool {
  cursor: help;
  position: relative;
  transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26) 0.2s;
} */

/*== common styles for both parts of tool tip ==*/
/* .tool::before,
.tool::after {
  left: 50%;
  position: absolute;
  opacity: 1;
  transform: scale(1) translateY(0);
  z-index: 100;
} */

/*== pointer tip ==*/
/* .tool::before {
  border-style: solid;
  border-width: 1em 0.75em 0 0.75em;
  border-color: #3e474f transparent transparent transparent;
  bottom: 100%;
  content: "";
  margin-left: -0.5em;
  transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26),
    opacity 0.65s 0.5s;
  transform: scale(0.6) translateY(-90%);
} */

/*== speech bubble ==*/
/* .tool::after {
  background: #3e474f;
  border-radius: 0.25em;
  bottom: 180%;
  color: #edeff0;
  content: attr(data-tip);
  margin-left: -8.75em;
  padding: 1em;
  transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26) 0.2s;
  transform: scale(0.6) translateY(50%);
  width: 17.5em;
} */

@media (max-width: 760px) {
  .tool::after {
    font-size: 0.75em;
    margin-left: -5em;
    width: 10em;
  }
}
