.btn-circle {
  background-color: #4d4c4f;
  width: 4vw;
  height: 4vw;
  border-radius: 50%;
  line-height: normal;
  margin: 5px;
  outline: none !important;
  border: none;
  z-index: 5;
}

.mob-btn {
  width: 12vw;
  height: 12vw;
}

.mob-icon {
  height: 8vw;
  width: 8vw;
}

.btn-circle.red {
  background-color: #ec4c3a;
}

button:active {
  outline: none !important;
  border: none;
}

.end-call-icon {
  margin: 0 auto;
  background: url("../../icons/icon_hangup.svg");
  height: 2.5vw;
  width: 2.5vw;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}
.audio-mute {
  margin: 0 auto;
  background: url("../../icons/icon_audio_mute.svg");
  height: 2vw;
  width: 2vw;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}

.audio-un-mute {
  margin: 0 auto;
  background: url("../../icons/icon_audio_unmute.svg");
  height: 2vw;
  width: 2vw;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}

.video-un-mute {
  margin: 0 auto;
  background: url("../../icons/icon_video_unmute.svg");
  height: 2vw;
  width: 2vw;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}

.video-mute {
  margin: 0 auto;
  background: url("../../icons/icon_video_mute.svg");
  height: 2vw;
  width: 2vw;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}

.fileShare {
  margin: 0 auto;
  background: url("../../icons/file_share.svg");
  height: 3vw;
  width: 3vw;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}

.screen-share-on {
  margin: 0 auto;
  background: url("../../icons/screenshare-enabled.svg");
  height: 2vw;
  width: 2vw;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}

.screen-share-off {
  margin: 0 auto;
  background: url("../../icons/screen_share_disabled.svg");
  height: 2vw;
  width: 2vw;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}

.display-mode-change {
  margin: 0 auto;
  background: url("../../icons/change_mode.svg");
  height: 2vw;
  width: 2vw;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}

.recording {
  margin: 0 auto;
  background: url("../../icons/recording.svg");
  height: 1.5vw;
  width: 1.5vw;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}
.stop-recording {
  margin: 0 auto;
  background: url("../../icons/cross-inside-circle.svg");
  height: 3vw;
  width: 3vw;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}

.display-mode-grid {
  margin: 0 auto;
  background: url("../../icons/grid_mode.svg");
  height: 2vw;
  width: 2vw;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}

.button-container {
  position: relative;
  display: inline-block;
  display: flex;
  justify-content: center;
  padding: 0.2%;
}

.button-background2 {
  background-color: rgba(46, 47, 48, 0.6);
  border-radius: 25px;
}

.transparent {
  opacity: 0.4;
}

.video-source-list {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.change-source-button {
  position: absolute;
  bottom: 5px;
  right: 5px;
  height: 1.5vw;
  width: 1.5vw;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 100;
}

.dropbtn {
  background-color: #4caf50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #555;
  min-width: 180px;
  padding: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 10000;
  border-radius: 8px;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #555;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

.more-option {
  margin: 0 auto;
  background: url("../../icons/record.svg");
  height: 2vw;
  width: 2vw;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}
.streaming {
  margin: 0 auto;
  background: url("../../icons/streaming.svg");
  height: 1.5vw;
  width: 1.5vw;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}
.padding-right-12 {
  right: 12%;
}
.padding-right-0 {
  right: 0%;
}
